@import url('https://fonts.cdnfonts.com/css/circular-std');

/*-------------------------
    Variables
-------------------------*/
:root {
  --color-primary: #259792;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-light: #FAFCFE;
  --color-dark: #8C8F97;
  --color-primary-link: #1f5a57;
  --color-dark-link: #7f8082;
  --color-link: #7f8082;

  --transition: all 0.3s ease-in-out;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: var(--transition);
  color: #2EBDB6;
}

span {
  display: inline-block;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: block;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.landing_container {
  width: 88%;
  margin-left: auto;
  margin-right: auto;
}

body {
  margin: 0;
  font-family: 'Circular Std', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*-------------------------
    Button
-------------------------*/

.btn {
  width: 100%;
}

.btn,
.btn-white,
.btn-md,
.btn-light,
.btn-account {
  width: 100%;
  padding: 10px 12px;
  background: var(--color-light);
  border-radius: 6px;
  font-style: normal;
  font-family: 'Circular Std', sans-serif;
  height: auto;
  text-align: center;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
  background-color: #151F32;
  border-radius: 6px;
  background: var(--flick-primary-6, #259792);
  color: #F7F8FA;
  overflow: hidden;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;


}

.btn::after,
.btn-md::after,
.btn-light::after,
.btn-account::after {
  content: "";
  height: 300px;
  width: 300px;
  background-color: #0d5653;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: -1;

}

/* .btn:hover,
.btn-md:hover,
.btn-light:hover {
  background-color: #0d5653;
  border-color: #0d5653;
  color: #F7F8FA;
}

.btn:hover::after,
.btn-white:hover::after,
.btn-md:hover::after,
.btn-landing::after,
.btn-light:hover::after {
  transform: translateY(-50%) translateX(-50%) scale(1);
} */

input::placeholder {
  color: #D0D5DD !important;
}

.btn-landing {
  width: fit-content;
  padding: 10px 12px;
  background: var(--color-primary);
  border-radius: 6px;
  font-style: normal;
  height: auto;
  text-align: center;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
  border-radius: 6px;
  color: var(--color-white);
  overflow: hidden;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.landing_page {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* align-items: center; */

}

.sec-btn {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 14px;
  border-radius: 6px;
  outline: none;
  overflow: hidden;
  border: 1px solid var(--Flick-Stroke, #EAECF0);
  background-color: white;
  color: var(--flick-secondary-5, #151F32);
  font-size: 14px;
  font-weight: 450;
  line-height: normal;
}

select,
select option {
  color: #000000;
}

select:invalid,
select option[value=""] {
  color: #d0d5dd;
}

[hidden] {
  display: none;
}

.custom-checkbox {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--Primary-600, #259792);
  cursor: pointer;
  outline: none; 
}

.custom-checkbox::before {
  content: '\2713'; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #259792; /* Color of the checkmark */
  font-family: 'Circular Std';
  font-size: 12px;
  font-weight: 900;
  display: none; /* Hide the checkmark by default */
}

.custom-checkbox:checked::before {
  display: block; /* Show the checkmark when the checkbox is checked */
}

.custom-checkbox:checked {
  background-color: var(--Primary-50, #EAF8F8);
  border-color: #259792;
  width: 16px;
  height: 16px;
  padding: 8px;
}

.custom-radio {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid var(--Primary-600, #259792);
  border-radius: 50%;
  cursor: pointer;
  outline: none; 
}

.custom-radio:checked {
  background-color: var(--Primary-50, #EAF8F8);
  border-color: #259792;
  width: 16px;
  height: 16px;
}
.custom-radio::before {
  content: '\2022'; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #259792; /* Color of the checkmark */
  font-size: 35px;
  font-weight: 900;
  display: none; /* Hide the checkmark by default */
}

.custom-radio:checked::before {
  display: block; /* Show the checkmark when the checkbox is checked */
}

