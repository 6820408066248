.connect-account-overlay {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  @media screen and (max-width: 280px) {
    max-width: 280px;
  }
  .blurred {
    filter: blur(1px);
    pointer-events: none;
  }
  .connect-account-main {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    padding-top: 169px;
    margin: 0 auto;
    @media screen and (max-width: "768px") {
      padding: 20px;
    }
    @media screen and (max-width: "280px") {
      font-size: 12px;
    }

    .flick-link-box {
      width: 24%;
      max-width: 347px;
      border-radius: 10px;
      background: #fff;
      @media screen and (max-width: 768px) {
        width: 100%;
        max-width: 100%;
      }

      .flick-link-title {
        width: 100%;
        display: flex;
        gap: 15px;
        padding: 18px 0px 18px 0px;
        border-bottom: 1px solid rgba(231, 234, 238, 0.4);
        .flick-link {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 4px;
          .link-demo {
            color: var(--gray-900, #101828);
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.32px;
          }
          .explore-flick {
            color: var(--Flick-Subtext, #8c8f97);
            text-align: center;
            font-size: 12px;
            font-weight: 450;
            line-height: normal;
            letter-spacing: -0.24px;
          }
        }
      }

      .flick-link-form {
        width: 100%;
        padding: 0px 21px 33px 21px;
        margin-top: 14px;
        .flick-link-inner {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-bottom: 24px;

          .input_container {
            position: relative;
            padding-top: 10px;
            width: 100%;
            .input-group {
              border-radius: 8px;
              border: 1px solid var(--Flick-Stroke, #eaecf0);
              background: #fff;
              display: flex;
              align-items: center;
              background: #fff;
              padding: 16px 10px 17px 10px;
              .input-name {
                color: var(--flick-secondary-5, #151f32);
                font-size: 12px;
                font-weight: 300;
                line-height: normal;
                border: none;
                width: 90%;
                &:focus {
                  outline: none;
                }
              }
              .input-name,
              textarea {
                caret-color: var(--flick-primary-5, #2ebdb6);
              }
            }
            .input-label {
              position: absolute;
              top: 0;
              left: 7px;
              color: var(--Flick-Subtext, #8c8f97);
              font-size: 12px;
              font-weight: 450;
              line-height: 20px;
              background: #fff;
              // padding: 4px;
            }

            .input-group.clicked {
              border: 1px solid var(--flick-primary-5, #2ebdb6);
            }

            .input-label.clicked {
              color: var(--flick-primary-5, #2ebdb6);
            }
          }
          .input-field {
            padding: 16px 10px 17px 10px;
            width: 100%;
            border-radius: 6px;
            border: 1px solid var(--Flick-Stroke, #eaecf0);
            background: var(--shade-white, #fff);
            outline: none;
            color: var(--flick-secondary-5, #151f32);
            font-size: 12px;
            font-weight: 300;
            line-height: normal;
          }
          .input-field,
          textarea {
            caret-color: var(--flick-primary-5, #2ebdb6);
          }
          .link-form-item {
            width: 100%;
            gap: 6px;
            .link_select_custom {
              width: 25%;
              font-size: 14px;
              font-weight: 450;
              line-height: 24px;
              border: none;
              padding: 12px 5px 12px 5px;
              display: flex;
              align-items: center;
              outline: none;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid #d0d5dd;
              background: var(--base-white, #fff);
              &:focus {
                outline: none;
              }
            }
            .link_select_custom_value {
              width: 80%;
              border: none;
              padding-top: 8px;
              padding-bottom: 8px;
              padding-left: 12px;
              border: 1px solid #d0d5dd;
              border-radius: 8px;
              padding-top: 8px;
              padding-bottom: 8px;
              &:focus {
                outline: none;
              }
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
    .connect-account-box {
      width: 24%;
      max-width: 347px;
      border-radius: 10px;
      background: #fff;
      //   padding-bottom: 33px;
      @media (max-width: 1024px) {
        width: 40%;
        max-width: none;
      }

      @media (max-width: "280px") {
        width: 20%;
      }
      @media screen and (max-width: 768px) {
        width: 80%;
      }

      @media screen and (max-width: 425px) {
        width: 90%;
      }

      .close-button {
        display: flex;
        justify-content: flex-end;
        margin-right: 15px;
        margin-top: 15px;
      }
      .flick-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 28px;
        margin-bottom: 24px;
      }
      .super-wallet {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-weight: lighter;
        line-height: normal;
        letter-spacing: -0.4px;
        margin-left: 20px;
        margin-right: 20px;
      }
      .flick-policy {
        padding-left: 20px;
        padding-right: 22px;
        margin-bottom: 27px;
      }
      .agree-to {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        margin-bottom: 10px;
        .select-continue {
          color: var(--Flick-Subtext, #8c8f97);
          text-align: center;
          font-size: 10px;
          font-weight: 450;
          line-height: 16px;
        }
        .flick-end-user {
          color: var(--flick-secondary-5, #151f32);
          font-size: 10px;
          font-weight: 450;
          line-height: 16px;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }

      .connect-effortless {
        display: flex;
        flex-direction: column;
        gap: 28px;
        margin-top: 21px;
        margin-bottom: 32px;
        padding-left: 20px;
        padding-right: 21px;
        @media screen and (max-width: 280px) {
          padding: 0px 10px 0px 10px;
        }

        .border-line {
          border-bottom: 1px solid var(--Flick-Stroke, #eaecf0);
        }

        .connect-title {
          width: 100%;
          display: flex;
          align-items: flex-start;
          gap: 16px;
          .connect-link {
            display: flex;
            flex-direction: column;
            gap: 7px;
          }
          .internet-mobile {
            display: flex;
            align-items: flex-start;
            gap: 16px;
          }
          .connect-your-account-link {
            display: flex;
            flex-direction: column;
            gap: 7px;
            margin-right: 28.5px;
            @media screen and (max-width: 280px) {
              margin-right: 0px;
            }
          }
          .connect-your-account {
            color: var(--flick-secondary-5, #151f32);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
          .securely-connect {
            color: var(--Flick-Subtext, #8c8f97);
            font-size: 12px;
            font-weight: 450;
            line-height: 18px;
          }
        }
      }
      .associated-account {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .link-associated {
          color: var(--gray-900, #101828);
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.32px;
          margin-top: 15px;
          margin-bottom: 4px;
        }
        .we-found {
          color: var(--Flick-Subtext, #8c8f97);
          text-align: center;
          font-size: 12px;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.24px;
          width: 75%;
        }
        .verify-identity {
          color: #000;
          text-align: center;
          font-size: 20px;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.4px;
          margin-top: 24px;
          margin-bottom: 6px;
        }
        .document-verify {
          color: #000;
          text-align: center;
          font-size: 16px;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.32px;
        }
      }
      .input-verify {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-bottom: 24px;
        .enter-number {
          color: var(--gray-700, #344054);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        .input-group {
          border-radius: 8px;
          border: 1px solid var(--gray-300, #d0d5dd);
          background: var(--base-white, #fff);
          display: flex;
          align-items: center;
          background: #fff;
          padding: 8px 12px;
          .verify-input {
            color: var(--flick-secondary-5, #151f32);
            font-size: 14px;
            font-weight: 450;
            line-height: 24px;
            border: none;
            width: 90%;
            &:focus {
              outline: none;
            }
          }
        }
      }
      .document-types {
        display: flex;
        flex-direction: column;
        gap: 28px;
        margin-bottom: 132px;
        .document-type {
          display: flex;
          gap: 12px;
          cursor: pointer;
          .choose-document {
            color: var(--flick-secondary-5, #151f32);
            font-size: 14px;
            font-weight: 450;
            line-height: 20px;
          }
        }
      }
      // Choose Your Bank
      .choose-bank {
        display: flex;
        justify-content: space-between;
        padding: 15px 15px 0px 15px;
        .choose-your-bank {
          color: #000;
          text-align: center;
          font-size: 14px;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }

      .selection {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.4px;
      }

      .face-id {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 54px;
        .selfie {
          color: #000;
          text-align: center;
          font-size: 20px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.4px;
          margin-top: 24px;
          margin-bottom: 6px;
        }
        .selfie-check {
          width: 90%;
          color: var(--Flick-Subtext, #8c8f97);
          text-align: center;
          font-size: 13px;
          font-weight: 450;
          line-height: 18px;
          margin-bottom: 24px;
        }
      }
      .progress-container {
        width: 90%;
        height: 8px;
        background-color: #e0e0e0;
        border-radius: 8px;
        margin-top: 8px;

        .progress-bar {
          height: 100%;
          background-color: #2ebdb6;
          border-radius: 4px;
          transition: width 0.3s ease-in-out;
        }
      }
      .progress-label {
        color: var(--gray-700, #344054);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px; 
      }
      //Enter Credentials

      .input_container {
        position: relative;
        padding-top: 15px;
        width: 100%;
        .input-group {
          border-radius: 8px;
          border: 1px solid var(--Flick-Stroke, #eaecf0);
          background: #fff;
          display: flex;
          align-items: center;
          background: #fff;
          padding: 16px 10px 17px 10px;
          .input-name {
            color: var(--flick-secondary-5, #151f32);
            font-size: 12px;
            font-weight: 300;
            line-height: normal;
            border: none;
            width: 90%;
            &:focus {
              outline: none;
            }
          }
          .input-name,
          textarea {
            caret-color: var(--flick-primary-5, #2ebdb6);
          }
        }
        .input-label {
          position: absolute;
          top: 0;
          left: 6px;
          color: var(--Flick-Subtext, #8c8f97);
          font-size: 12px;
          font-weight: 450;
          line-height: 20px;
          background: #fff;
          padding: 4px;
        }

        .input-group.clicked {
          border: 1px solid var(--flick-primary-5, #2ebdb6);
        }

        .input-label.clicked {
          color: var(--flick-primary-5, #2ebdb6);
        }
      }
      .input-field {
        padding: 16px 10px 17px 10px;
        width: 100%;
        border-radius: 6px;
        border: 1px solid var(--Flick-Stroke, #eaecf0);
        background: var(--shade-white, #fff);
        outline: none;
        color: var(--flick-secondary-5, #151f32);
        font-size: 12px;
        font-weight: 300;
        line-height: normal;
      }
      .input-field,
      textarea {
        caret-color: var(--flick-primary-5, #2ebdb6);
      }

      //Enter Credentials
      .enter-credentials-box {
        padding: 0px 21px 21px 21px;
      }
      .img-logo {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .enter-credentials {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 16px;
        .enter-your-credentials {
          color: var(--flick-secondary-5, #151f32);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        .credentials {
          color: var(--Flick-Subtext, #8c8f97);
          font-size: 12px;
          font-weight: 450;
          line-height: 18px;
          @media screen and (max-width: 280px) {
            font-size: 10px;
          }
        }
      }
      .secured-flick {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 24px;
      }

      //Connect Account
      .connect-accounts-image {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--Flick-Stroke, #eaecf0);
        padding-bottom: 20px;
      }
      .account-name {
        color: var(--Flick-Subtext, #8c8f97);
        font-size: 10px;
        font-weight: 450;
        line-height: 18px;
      }
      .account-type-container {
        display: flex;
        flex-direction: column;
        gap: 14px;
        overflow-y: auto;
        max-height: 270px;
        margin-top: 24px;
        margin-bottom: 24px;

        .account-type {
          display: flex;
          justify-content: space-between;
          border-radius: 8px;
          border: 1px solid var(--Flick-Stroke, #eaecf0);
          background: #fff;
          padding: 11px 11px 10px 11px;
          @media screen and (max-width: 280px) {
            padding: 11px 5px 10px 5px;
          }

          .account-type-text {
            display: flex;
            flex-direction: column;
            gap: 7px;
            .type-of-account {
              color: var(--flick-secondary-5, #151f32);
              font-size: 12px;
              font-weight: 450;
              line-height: normal;
              @media screen and (max-width: 280px) {
                font-size: 11px;
              }
            }
            .account-number {
              color: var(--Subtext-2, #606b81);
              font-size: 12px;
              font-weight: 300;
              line-height: normal;
            }
            .verification {
              color: var(--flick-secondary-5, #151f32);
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
            }
            .verify-info {
              color: var(--Flick-Subtext, #8c8f97);
              font-size: 12px;
              font-weight: 450;
              line-height: 18px;
            }
          }
        }
      }

      //Account Connected
      .success-flick {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
      }
      .connected {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 43px;
        // padding: 24px 63px 80px 63px;
        gap: 4px;
        @media screen and (max-width: 280px) {
          padding: 24px 20px 80px 20px;
        }
      }

      .account-connected {
        color: var(--flick-secondary-5, #151f32);
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }
      .your-account {
        color: var(--Flick-Subtext, #8c8f97);
        text-align: center;
        font-size: 12px;
        font-weight: 450;
        line-height: 18px;
        width: 70%;
      }
      .portco-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 22.5px;
        padding-left: 22px;
        padding-right: 22px;
        gap: 10px;
      }

      //SelectBanks

      .select-banks {
        display: flex;
        flex-direction: column;
        padding: 27px 21px 21.5px 21px;
        .search-banks {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 11px 14px;
          border-radius: 8px;
          border: 1px solid var(--Flick-Stroke, #eaecf0);
          box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
            0px 12px 16px -4px rgba(16, 24, 40, 0.08);
          margin-bottom: 22px;
          .search-field {
            width: 100%;
            border: none;
            outline: none;
            color: var(--flick-secondary-5, #151f32);
            font-size: 12px;
            font-weight: 450;
            line-height: normal;
            line-height: 20px;
            letter-spacing: 0.24px;
          }
          .search-field,
          textarea {
            caret-color: var(--flick-primary-5, #2ebdb6);
          }
        }
        .banking {
          display: flex;
          margin-bottom: 16px;
          gap: 5px;
          .banking-option {
            color: var(--flick-secondary-5, #151f32);
            font-size: 14px;
            font-weight: 450;
            line-height: 20px;
            text-align: center;
            padding: 10px 49px 10px 49px;
          }
          .banking-active {
            border-bottom: 1px solid #259792;
          }
        }

        .bank-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          max-height: 220px;
          overflow-y: auto;
          gap: 8px;
          margin-bottom: 21.5px;
          .select-bank {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background: #f8f8f8;
            flex-basis: calc(33.33% - 8px);
            max-width: calc(33.33% - 8px);
            height: 96px;
            padding: 20px 23px;
            @media screen and (max-width: 280px) {
              padding: 0px 10px 0px 10px;
            }
            .selected {
              background: #2ebdb6;
              color: #ffffff;
            }

            .bank-name {
              color: var(--flick-secondary-5, #151f32);
              font-size: 14px;
              font-weight: 450;
              line-height: 20px;
              margin-top: 12px;
              @media screen and (max-width: 280px) {
                font-size: 12px;
              }
            }
            .selected-text {
              color: #ffffff;
            }
          }
          .selected {
            background-color: #2ebdb6;
          }
        }
      }
    }
  }
  .custom-toast-container {
    width: 29%;
    @media screen and (max-width: 280px) {
      width: 280px;
    }
  }
}
.foot-note {
  display: flex;
  width: 100%;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--radius-none, 0px);
  background: #259792;
  color: #fff;
  font-size: 14px;
  font-weight: 450;
  line-height: normal;
}
//OTP

.enter-otp-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 24px 24px;
  gap: 14px;
  width: 100%;
  max-width: 310px;
  position: fixed;
  z-index: 1;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background: var(--base-white, #fff);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  .modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .enter-otp {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--flick-secondary-5, #151f32);
      font-size: 14px;
      font-weight: 450;
      line-height: 20px;
    }
    .input-otp {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 6px;

      .input-otp-field {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 100%;
        .otp {
          display: flex;
          align-items: center;
          justify-content: space-around;
          outline: none;
          width: 70%;
          height: 45px;
          border-radius: 8px;
          border: 2px solid var(--flick-primary-5, #2ebdb6);
          background: var(--base-white, #fff);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: var(--flick-primary-5, #2ebdb6);
          text-align: center;
          font-size: 20px;
          font-weight: 250;
          line-height: 50px;
          letter-spacing: 10px;
        }
      }
    }
    .otp-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      padding: 10px 12px;
      border-radius: 6px;
      height: auto;
      position: relative;
      z-index: 1;
      background-color: #151f32;
      border-radius: 6px;
      background: var(--flick-primary-6, #259792);
      color: #f7f8fa;
      overflow: hidden;
      border: none;
      outline: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.container_overlay {
  width: 100%;
  position: relative;
  background: red;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading_overlay {
  width: 100%;
  height: 100%;
  position: absolute;

  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(46, 189, 182);
}
.loader_icon {
  margin: auto;
}

// Not found

.error_container {
  width: 100%;
  height: 100vh;
  background: #f6f7f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 6rem;
  margin: 0 auto;
}

.error_content {
  border-radius: 10px;
  background: #fff;
  width: 100%;
  max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding-bottom: 1.6rem;
  margin-top: 25vh;
  .invalid_content {
    margin-top: 5vh;
  }
  background: #fff;
  width: 100%;
  max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding-bottom: 4rem;
  margin-top: 25vh;
  .invalid_content {
    margin-top: 5vh;
  }

  .title {
    color: var(--gray-900, #101828);
    text-align: center;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    margin-top: 3rem;
  }
  .text {
    color: #fff;
    font-family: Circular Std;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 152.523%; /* 61.009px */
    background: #ed1c24;
    width: fit-content;
    padding: 1rem;
    margin-top: 3rem;
  }
}

.securedBtn {
  padding: 12px 24px;
  margin-top: 32px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background: #fff;
  display: flex;
  gap: 8px;
  cursor: pointer;
}
.secured_content {
  display: flex;
  gap: 4px;
  align-items: center;
}
.secured_p {
  color: #000;
  font-family: Circular Std;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.35px;
}

.error_invalid {
  font-family: Circular Std;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.35px;
  color: #ababab;
  margin: 0 0 50px;
}

.error_invalid_header {
  color: #2f3d4d;
  line-height: 1.4;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
}
